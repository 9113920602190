<template>

    <b-sidebar
        id="learnerPerson-sidebar"
        title="Mes apprenants"
        backdrop
        shadow
    >

        <b-button
            v-if="showHomeSummaryLink"
            :to="{ name: 'home-summary' }"
            class="d-block mx-3 mb-4"
            variant="outline-dark"
        >
            <font-awesome-icon
                :icon="['fad', 'folder-open']"
                fixed-width
            />
            Voir tous les livrets
        </b-button>

        <div class="mx-3 mb-4">
            <b-form-checkbox
                v-model="isCurrent"
                switch
            >
                Uniquement les suivis en cours
            </b-form-checkbox>
        </div>

        <learner-person-index
            v-slot="{ icon, isPending, learnerPeople, title, upload }"
            :is-current="isCurrent"
        >
            <b-card
                border-variant="0"
                class="mb-4"
                no-body
            >

                <b-card-header class="d-flex pb-2">

                    <upload-avatar
                        :icon="icon"
                        :upload="upload"
                        width="25px"
                    />

                    <div class="flex-fill ml-2">
                        {{ title }}
                    </div>

                </b-card-header>

                <b-list-group
                    v-if="learnerPeople.length === 0"
                    flush
                >
                    <b-list-group-item
                        class="text-center"
                        variant="warning"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'empty-set']"
                            fixed-width
                        />
                        Vous n'avez aucun suivi <b v-if="isCurrent">en cours</b>.<br>
                    </b-list-group-item>
                </b-list-group>

                <b-list-group
                    v-else
                    flush
                >
                    <b-list-group-item
                        v-for="learnerPerson in learnerPeople"
                        :key="learnerPerson.$.id"
                        :class="{ 'active': learnerPerson.$.id === selectedLearnerPersonId }"
                        :to="{
                            name: 'questionnaireEntry-index',
                            params: { mandatory: 1, questionnaireCategoryId: 0 },
                            query: { learner_person_id: learnerPerson.$.id },
                        }"
                        class="d-flex"
                        exact
                    >

                        <b-skeleton-img
                            v-if="isPending"
                            class="d-inline-block mr-1"
                            height="25px"
                            width="25px"
                            no-aspect
                        />

                        <upload-avatar
                            v-else
                            :upload="learnerPerson.$.avatar"
                            icon="user-graduate"
                            width="25px"
                            rounded
                        />

                        <b-skeleton
                            v-if="isPending"
                            class="d-inline-block w-75 ml-2"
                        />

                        <div
                            v-else
                            class="flex-fill ml-2"
                        >
                            {{ learnerPerson.$.fullname }}
                        </div>

                    </b-list-group-item>
                </b-list-group>

            </b-card>
        </learner-person-index>

    </b-sidebar>

</template>



<script>

    export default {
        data: () => ({
            isCurrent: true,
        }),
        computed: {
            selectedLearnerPersonId: (vue) => vue.$store.getters['learnerPerson/id'],
            showHomeSummaryLink: (vue) => vue.selectedLearnerPersonId && vue.$route.name !== 'home-summary',
        },
    };

</script>
