<template>

    <b-container>

        <h1 class="mb-4 text-center">
            {{ pageTitle }}
        </h1>

        <b-row>

            <b-col
                class="mb-4"
                lg="6"
            >
                <user-edit :value="user" />
            </b-col>

            <b-col
                v-for="businessPerson in businessPeople"
                :key="businessPerson.$.id"
                class="mb-4"
                lg="6"
            >
                <business-person-edit
                    :value="businessPerson"
                    @input="refresh"
                />
            </b-col>

            <b-col
                v-for="educationPerson in educationPeople"
                :key="educationPerson.$.id"
                class="mb-4"
                lg="6"
            >
                <education-person-edit
                    :value="educationPerson"
                    @input="refresh"
                />
            </b-col>

        </b-row>

    </b-container>

</template>



<script>

    import User from '@core/models/auth/user';

    export default {
        computed: {
            businessPeople: (vue) => vue.$store.getters['auth/businessPeople'],
            educationPeople: (vue) => vue.$store.getters['auth/educationPeople'],
            user: (vue) => vue.$store.getters['auth/user'],
            pageTitle: () => 'Mon profil',
        },
        methods: {
            refresh() {
                new User().request().show();
            },
        },
    };

</script>
