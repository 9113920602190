<template>

    <questionnaire-entry-show
        :questionnaire-entry-id="questionnaireEntryId"
        @input:questionnaireEntry="$emit('input:questionnaireEntry', $event)"
    />

</template>



<script>

    export default {
        props: {
            questionnaireEntryId: {
                type: [Number, String],
                required: true,
            },
        },
    };

</script>
