import LearnerPerson from '@core/models/learnerPerson';

export default {

    namespaced: true,

    state: () => ({
        learnerPerson: undefined,
    }),

    getters: {
        copy: (state) => (state.learnerPerson ? new LearnerPerson({ ...state.learnerPerson.$ }) : undefined),
        id: (state) => (state.learnerPerson ? state.learnerPerson.$.id : undefined),
        learnerEducationStudy: (state) => (
            state.learnerPerson ? state.learnerPerson.learnerEducationStudy : undefined
        ),
        pending: (state) => (state.learnerPerson ? state.learnerPerson.isPending : false),
    },

    mutations: {
        learnerPerson: (state, learnerPerson) => { state.learnerPerson = learnerPerson; },
    },

    actions: {
        set: ({ commit }, id) => {
            const localLearnerPerson = new LearnerPerson({ id });
            commit('learnerPerson', localLearnerPerson);
            return localLearnerPerson.request().show().catch(() => commit('learnerPerson', undefined));
        },
        unset: ({ commit }) => commit('learnerPerson', undefined),
    },

};
