<template>

    <div
        v-if="isAuthenticated"
        class="d-flex flex-column"
    >

        <navbar-top
            v-if="printMode !== true"
            toggleable="lg"
            type="dark"
            variant="primary"
        >

            <upload-avatar
                v-if="upload"
                slot="brand"
                :upload="upload"
                height="40px"
                icon="landmark-alt"
            />

            <b-navbar-nav slot="mobile">

                <b-nav-item
                    v-if="learnerPerson"
                    v-b-toggle.learnerPerson-sidebar
                >
                    <font-awesome-icon
                        :icon="['fad', 'user-graduate']"
                        fixed-width
                    />
                    Mes apprenants
                </b-nav-item>

                <b-nav-item
                    v-else
                    :to="{ name: 'home-summary' }"
                >
                    <font-awesome-icon
                        :icon="['fad', 'user-graduate']"
                        fixed-width
                    />
                    Mes apprenants
                </b-nav-item>

            </b-navbar-nav>

            <template
                v-if="learnerPerson"
                slot="left"
            >

                <b-nav-item
                    v-for="mandatory in [1, 0]"
                    :key="mandatory"
                    :to="{ name: 'questionnaireEntry-index', params: { mandatory, questionnaireCategoryId: 0 } }"
                >
                    <font-awesome-icon
                        :icon="['fad', 'folder-open']"
                        fixed-width
                    />
                    {{ mandatoryNames[mandatory] }}
                </b-nav-item>

                <b-nav-item :to="{ name: 'businessApprenticeContract-index' }">
                    <font-awesome-icon
                        :icon="['fad', 'file-contract']"
                        fixed-width
                    />
                    Missions & contrats
                </b-nav-item>

                <b-nav-item-dropdown
                    v-if="scheduleUrl"
                    id="navbar-schedule"
                    no-caret
                >

                    <template slot="button-content">
                        <font-awesome-icon
                            :icon="['fad', 'calendar']"
                            fixed-width
                        />
                        Planning
                    </template>

                    <b-dropdown-item
                        :href="`${scheduleUrl}?${scheduleParameters}`"
                        target="_blank"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'external-link']"
                            fixed-width
                        />
                        Ouvrir dans un nouvel onglet
                    </b-dropdown-item>

                    <b-dropdown-divider />

                    <b-dropdown-text>
                        Pour vous authentifier, utilisez les mêmes identifiants que pour {{ platformName }}.
                    </b-dropdown-text>

                </b-nav-item-dropdown>

            </template>

        </navbar-top>

        <div
            v-if="showLearnerPerson"
            class="breadcrumb flex-nowrap align-items-center mb-0 py-0 bg-white"
        >

            <b-skeleton
                v-if="learnerPersonPending"
                height="50px"
                width="50px"
                no-aspect
            />

            <upload-avatar
                v-else-if="learnerEducationStudy"
                :upload="learnerEducationStudy.$.educationStudy.$.educationProgramme.$.school.$.logoSm"
                icon="university"
                height="40px"
            />

            <b-breadcrumb class="flex-fill mb-0 pr-0 border-0 bg-white">

                <b-breadcrumb-item
                    class="text-body"
                    active
                >

                    <b-skeleton
                        v-if="learnerPersonPending"
                        class="w-25"
                    />

                    <template v-else>
                        {{ learnerPerson.$.fullname }}
                    </template>

                </b-breadcrumb-item>

                <b-breadcrumb-item
                    v-if="learnerEducationStudy"
                    class="text-body"
                    active
                >

                    <b-skeleton
                        v-if="learnerPersonPending"
                        class="w-25"
                    />

                    <template v-else>
                        {{ learnerEducationStudy.$.educationStudy.$.name }}
                    </template>

                </b-breadcrumb-item>

            </b-breadcrumb>

        </div>

        <transition
            :enter-active-class="enterTransition"
            :leave-active-class="leaveTransition"
            mode="out-in"
            appear
        >
            <router-view class="py-4" />
        </transition>

        <learner-person-sidebar v-if="printMode !== true" />

    </div>

    <div
        v-else
        class="page-loader"
    >
        <div />
    </div>

</template>



<script>

    import QuestionnaireSurvey from '@core/models/questionnaireSurvey';
    import User from '@core/models/auth/user';

    export default {
        data: () => ({
            enterTransition: 'fadeIn',
            leaveTransition: 'fadeOut',
        }),
        computed: {
            isAuthenticated: (vue) => vue.$store.getters['auth/isConnected']
                && vue.$store.getters['auth/canAccessMentorClient'],
            learnerEducationStudy: (vue) => vue.$store.getters['learnerPerson/learnerEducationStudy'],
            learnerPerson: (vue) => vue.$store.getters['learnerPerson/copy'],
            learnerPersonId: (vue) => vue.$store.getters['learnerPerson/id'],
            learnerPersonPending: (vue) => vue.$store.getters['learnerPerson/pending'],
            mandatoryNames: () => QuestionnaireSurvey.mandatoryNames,
            platformName: (vue) => vue.$store.getters['center/platformName'],
            scheduleParameters: (vue) => new URLSearchParams({
                email: vue.$store.getters['auth/user'].$.email,
                firstname: vue.$store.getters['auth/user'].$.firstname,
                lastname: vue.$store.getters['auth/user'].$.lastname,
            }),
            scheduleUrl: () => import.meta.env.VITE_SCHEDULE_URL,
            showLearnerPerson: (vue) => vue.$route.meta.showLearnerPerson && vue.learnerPerson,
            upload: (vue) => vue.$store.getters['center/platformLogoSm'],
        },
        watch: {
            $route: {
                handler(to, from) {
                    if (from && to.meta.navigationLevel > from.meta.navigationLevel) {
                        this.enterTransition = 'fadeInRight';
                        this.leaveTransition = 'fadeOutLeft';
                    } else if (from && to.meta.navigationLevel < from.meta.navigationLevel) {
                        this.enterTransition = 'fadeInLeft';
                        this.leaveTransition = 'fadeOutRight';
                    } else {
                        this.enterTransition = 'fadeIn';
                        this.leaveTransition = 'fadeOut';
                    }
                    if (parseInt(to.query.learner_person_id, 10) !== this.learnerPersonId) {
                        if (to.query.learner_person_id) {
                            this.$store.dispatch('learnerPerson/set', parseInt(to.query.learner_person_id, 10));
                        } else {
                            this.$store.dispatch('learnerPerson/unset');
                        }
                    }
                },
                immediate: true,
            },
        },
        created() {
            new User().request().show().catch(() => {});
        },
    };

</script>
