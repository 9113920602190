import Vue from 'vue';
import VueRouter from 'vue-router';
import Errors from '@core/request/errors';
import PanelRoutes from '@mentor/router/panel';
import PlainRoutes from '@core/routes';

Vue.use(VueRouter);

const router = new VueRouter({
    base: import.meta.env.BASE_URL,
    linkActiveClass: 'active',
    mode: 'history',
    routes: [
        ...PanelRoutes,
        ...PlainRoutes,
        {
            path: '*',
            component: {
                render: () => null,
                mounted() {
                    this.$nextTick(() => Errors[404]());
                    this.$router.replace('/');
                },
            },
        },
    ],
    scrollBehavior: () => ({ behavior: 'smooth', x: 0, y: 0 }),
});

router.beforeEach((to, from, next) => {
    if (from.query.learner_person_id && to.query.learner_person_id === undefined && to.name !== 'login-form') {
        next({ ...to, query: { ...to.query, learner_person_id: from.query.learner_person_id } });
    } else {
        next();
    }
});

export default router;
