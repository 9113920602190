/* eslint-disable import/first */
/* eslint-disable import/order */

import Vue from 'vue';
import '@mentor/style.scss';



/**
 * Setup FontAwesome
 */

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import * as duotoneIcons from '@core/fontawesome/duotone';
import * as brandsIcons from '@core/fontawesome/brands';

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Object.values(duotoneIcons).forEach((icon) => FontAwesomeLibrary.add(icon));
Object.values(brandsIcons).forEach((icon) => FontAwesomeLibrary.add(icon));



/**
 * Setup BootstrapVue
 */

import * as bootstrapVue from '@core/bootstrap-vue';

Object.values(bootstrapVue).forEach((component) => Vue.use(component));



/**
 * Setup PortalVue
 */

import PortalVue from 'portal-vue';

Vue.use(PortalVue);



/**
 * Setup Pusher
 */

import Pusher from 'pusher-js';

window.Pusher = Pusher;



/**
 * Setup components
 */

const coreComponents = import.meta.glob('@core/components/*.vue', { eager: true });
const mentorComponents = import.meta.glob('@mentor/components/*.vue', { eager: true });

Object.entries({
    ...coreComponents,
    ...mentorComponents,
}).forEach(([name, component]) => Vue.component(name.split('/').pop().split('.').shift(), component.default));



/**
 * Start app
 */

import Center from '@core/models/center';
import Print from '@core/mixins/print';
import PageTitle from '@core/mixins/pageTitle';
import Router from '@mentor/router';
import Sentry from '@core/sentry';
import Store from '@mentor/store';
import Toast from '@core/mixins/toast';

Sentry(Router);

Vue.mixin(Print);
Vue.mixin(PageTitle);

window.app = new Vue({
    mixins: [Toast],
    router: Router,
    store: Store,
    created() {
        if (this.printMode !== true) {
            this.$store.dispatch('maintenance/fetch');
            setInterval(() => this.$store.dispatch('maintenance/fetch'), 600000);
            new Center({ id: 1 }).request().silent().show();
        }
    },
    render: (render) => render('div', { class: 'min-vh-100' }, [
        render('transition', { props: { name: 'fade', mode: 'out-in', appear: true } }, [
            render('router-view', { class: 'min-vh-100' }),
        ]),
    ]),
}).$mount('#app');
