import Vue from 'vue';
import Vuex from 'vuex';
import Auth from '@core/store/auth';
import Center from '@core/store/center';
import LearnerPerson from '@mentor/store/learnerPerson';
import Maintenance from '@core/store/maintenance';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: Auth,
        center: Center,
        learnerPerson: LearnerPerson,
        maintenance: Maintenance,
    },
});
