import BusinessApprenticeContractIndex from '@mentor/views/BusinessApprenticeContractIndex.vue';
import BusinessApprenticeContractShow from '@mentor/views/BusinessApprenticeContractShow.vue';
import HomeSummary from '@mentor/views/HomeSummary.vue';
import QuestionnaireEntryIndex from '@mentor/views/QuestionnaireEntryIndex.vue';
import QuestionnaireEntryShow from '@mentor/views/QuestionnaireEntryShow.vue';
import LayoutPanel from '@mentor/components/LayoutPanel.vue';
import UserEdit from '@mentor/views/UserEdit.vue';

export default [
    {
        path: '/',
        component: LayoutPanel,
        children: [
            {
                path: '/',
                redirect: (to) => ({ name: 'home-summary', query: { ...to.query } }),
            },
            {
                path: 'accueil',
                name: 'home-summary',
                component: HomeSummary,
                meta: { navigationLevel: 1 },
            },
            {
                path: 'livret/:mandatory/:questionnaireCategoryId',
                name: 'questionnaireEntry-index',
                component: QuestionnaireEntryIndex,
                meta: { navigationLevel: 1, showLearnerPerson: true },
                props: true,
                children: [
                    {
                        path: ':questionnaireEntryId',
                        name: 'questionnaireEntry-show',
                        component: QuestionnaireEntryShow,
                        meta: { showLearnerPerson: true },
                        props: true,
                    },
                ],
            },
            {
                path: 'missions-contrats',
                name: 'businessApprenticeContract-index',
                component: BusinessApprenticeContractIndex,
                meta: { navigationLevel: 1, showLearnerPerson: true },
                children: [
                    {
                        path: ':businessApprenticeContractId',
                        name: 'businessApprenticeContract-show',
                        component: BusinessApprenticeContractShow,
                        meta: { showLearnerPerson: true },
                        props: true,
                    },
                ],
            },
            {
                path: 'mon-compte',
                name: 'user-edit',
                component: UserEdit,
                meta: { navigationLevel: 1 },
            },
        ],
    },
];
