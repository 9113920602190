<template>

    <b-card
        header="Compte utilisateur"
        header-class="text-center"
        header-tag="h2"
    >
        <b-row
            tag="form"
            @submit.prevent="formSubmit"
        >

            <b-col
                order="2"
                sm="8"
            >

                <form-group
                    id="view-user-edit-firstname"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.firstname"
                    :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.firstname"
                    label="Prénom"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.firstname"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-user-edit-lastname"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.lastname"
                    :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.lastname"
                    label="Nom"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.lastname"
                        v-bind="inputProps"
                        :plaintext="formInitialModel.$.updatable !== true"
                    />
                </form-group>

                <form-group
                    id="view-user-edit-email"
                    v-slot="inputProps"
                    :error="formModifiedModel.errors.email"
                    :left-icon="
                        formInitialModel.$.updatable && formInitialModel.$.updatableEmail ? 'envelope' : undefined
                    "
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.email"
                    label="Adresse e-mail"
                    business-apprentice-agreement
                    required
                >
                    <b-form-input
                        v-model="formModifiedModel.$.email"
                        v-bind="inputProps"
                        :plaintext="
                            formInitialModel.$.updatable !== true
                                || formInitialModel.$.updatableEmail !== true
                        "
                        type="email"
                    />
                </form-group>

            </b-col>

            <b-col
                order="1"
                sm="4"
            >
                <image-form-group
                    id="view-user-edit-avatar"
                    v-model="formModifiedModel.$.avatar"
                    :delete-upload.sync="formModifiedModel.$.delete_avatar"
                    :disabled="formInitialModel.$.updatable !== true"
                    :error="formModifiedModel.errors.avatar"
                    :initial-upload="formInitialModel.$.avatar"
                    :pending="formInitialModel.isPending"
                    :state="formModifiedModel.states.avatar"
                    icon="user-tie"
                    rounded
                />
            </b-col>

            <b-col
                v-if="formInitialModel.$.updatable"
                class="text-right"
                order="3"
                md="12"
            >
                <button-submit
                    :is-pending="formIsPending"
                    icon="edit"
                    variant="success"
                >
                    Enregistrer le compte
                </button-submit>
            </b-col>

        </b-row>
    </b-card>

</template>



<script>

    import Form from '@core/mixins/form';
    import User from '@core/models/user';

    export default {
        mixins: [Form],
        props: {
            value: {
                type: User,
                required: true,
            },
        },
        computed: {
            avatar: {
                get: (vue) => (vue.formModifiedModel.$.avatar instanceof File ? vue.formModifiedModel.$.avatar : null),
                set(avatar) {
                    if (avatar || this.formModifiedModel.$.avatar instanceof File) {
                        this.formModifiedModel.$.avatar = avatar;
                    }
                },
            },
            formInitialModel: {
                get: (vue) => vue.value,
                set(value) {
                    this.$emit('input', value);
                },
            },
        },
        methods: {
            formSubmitSuccessful(updatedUser) {
                this.$root.toastSuccess(
                    'Les informations du compte utilisateur ont été modifiées',
                    "L'enregistrement des informations a réussi.",
                );
                this.formInitialModel = new User(updatedUser.$);
            },
        },
    };

</script>
