<template>

    <b-card
        :header="`Profil ${formInitialModel.$.businessCompany.$.name}`"
        header-class="text-center"
        header-tag="h2"
        tag="form"
        @submit.prevent="formSubmit"
    >

        <form-group
            id="view-businessPerson-edit-title"
            v-slot="inputProps"
            :error="formModifiedModel.errors.title"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.title"
            label="Titre"
            required
        >
            <b-form-radio-group
                v-model="formModifiedModel.$.title"
                v-bind="inputProps"
                :disabled="formInitialModel.$.updatable !== true"
                :options="titles"
            />
        </form-group>

        <form-group
            id="view-businessPerson-edit-job-type"
            v-slot="inputProps"
            :error="formModifiedModel.errors.job_type"
            :left-icon="formInitialModel.$.updatable ? 'tag' : undefined"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.job_type"
            label="Département"
            required
        >
            <b-form-select
                v-if="formInitialModel.$.updatable"
                v-model="formModifiedModel.$.job_type"
                v-bind="inputProps"
                :options="jobTypeOptions"
            />
            <b-form-input
                v-else
                v-bind="inputProps"
                :value="jobTypes[formModifiedModel.$.job_type]"
                plaintext
            />
        </form-group>

        <form-group
            id="view-businessPerson-edit-job-title"
            v-slot="inputProps"
            :error="formModifiedModel.errors.job_title"
            :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.job_title"
            label="Intitulé de poste"
            required
        >
            <b-form-input
                v-model="formModifiedModel.$.job_title"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
            />
        </form-group>

        <form-group
            id="view-businessPerson-edit-loacation"
            v-slot="inputProps"
            :error="formModifiedModel.errors.location"
            :left-icon="formInitialModel.$.updatable ? 'map-marker' : undefined"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.location"
            label="Zone géographique"
        >
            <b-form-input
                v-model="formModifiedModel.$.location"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
            />
        </form-group>

        <form-group
            id="view-businessPerson-edit-phone"
            v-slot="inputProps"
            :error="formModifiedModel.errors.phone"
            :left-icon="formInitialModel.$.updatable ? 'mobile' : undefined"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.phone"
            label="Téléphone"
        >
            <b-form-input
                v-model="formModifiedModel.$.phone"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                pattern="^\+?\d{7,14}$"
                title="7 à 14 chiffres"
            />
        </form-group>

        <div
            v-if="formInitialModel.$.updatable"
            class="text-right"
        >
            <button-submit
                :is-pending="formIsPending"
                icon="edit"
                variant="success"
            >
                Enregistrer le profil
            </button-submit>
        </div>

    </b-card>

</template>



<script>

    import Form from '@core/mixins/form';
    import BusinessPerson from '@core/models/businessPerson';

    export default {
        mixins: [Form],
        props: {
            value: {
                type: BusinessPerson,
                required: true,
            },
        },
        computed: {
            formInitialModel: {
                get: (vue) => vue.value,
                set(value) {
                    this.$emit('input', value);
                },
            },
            jobTypes: () => BusinessPerson.jobTypes,
            jobTypeOptions: () => Object.entries(BusinessPerson.jobTypes).map(
                ([value, text]) => ({ text, value }),
            ),
            titles: () => Object.entries(BusinessPerson.titles).map(
                ([value, text]) => ({ text, value }),
            ),
        },
        methods: {
            formSubmitSuccessful(updatedBusinessPerson) {
                this.$root.toastSuccess(
                    `Les informations du profil ${updatedBusinessPerson.$.businessCompany.$.name} ont été modifiées`,
                    "L'enregistrement des informations a réussi.",
                );
                this.formInitialModel = new BusinessPerson(updatedBusinessPerson.$);
            },
        },
    };

</script>
