<template>

    <questionnaire-entry-index
        :learner-person-id="$store.getters['learnerPerson/id']"
        :mandatory="mandatory === '1' || mandatory === 1"
        :questionnaire-category-id="parseInt(questionnaireCategoryId)"
    />

</template>



<script>

    export default {
        beforeRouteEnter: (to, from, next) => {
            if (to.query.learner_person_id === undefined) {
                next({ name: 'home-summary' });
            } else {
                next();
            }
        },
        props: {
            mandatory: {
                type: [Number, String],
                required: true,
            },
            questionnaireCategoryId: {
                type: [String, Number],
                required: true,
            },
        },
    };

</script>
