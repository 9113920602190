<template>

    <b-card
        bg-variant="light"
        no-body
    >

        <b-card-header class="d-flex align-items-center">

            <b-skeleton-img
                v-if="isPending"
                class="mr-3"
                height="50px"
                width="50px"
                no-aspect
            />

            <upload-avatar
                v-else
                :upload="businessBranch.$.businessCompany.$.logo"
                icon="industry-alt"
                width="50px"
                rounded
            />

            <h2 class="flex-fill mb-0 ml-2">

                <b-skeleton
                    v-if="isPending"
                    class="w-75"
                    variant="dark"
                />

                <div v-else>
                    {{ businessBranch.$.businessCompany.$.name }}
                </div>

                <b-skeleton
                    v-if="isPending"
                    class="w-50"
                    variant="light"
                />

                <small
                    v-else
                    class="text-muted"
                >
                    {{ title }}
                </small>

            </h2>

        </b-card-header>

        <b-card-body>

            <address-show
                :address="businessBranch.$.office"
                :pending="isPending"
            />

            <b-skeleton
                v-if="isPending"
                class="w-100"
            />

            <div
                v-else
                :key="businessBranch.$.email"
            >
                <a :href="`mailto:${businessBranch.$.email}`">
                    {{ businessBranch.$.email }}
                </a>
            </div>

        </b-card-body>

    </b-card>

</template>



<script>

    import BusinessBranch from '@core/models/businessBranch';

    export default {
        props: {
            businessBranch: {
                type: BusinessBranch,
                required: true,
            },
            isPending: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                required: true,
            },
        },
    };

</script>
