<template>

    <div>

        <b-alert
            :show="businessPeople.length === 0"
            variant="warning"
        >
            <font-awesome-icon
                :icon="['fad', 'empty-set']"
                fixed-width
            />
            Ce contrat d'apprentissage n'a aucun maître d'apprentissage.
        </b-alert>

        <business-person-card
            v-for="businessPerson in businessPeople"
            :key="businessPerson.$.id"
            :business-person="businessPerson"
            :is-pending="isPending"
            class="mb-4"
            header-tag="h2"
            no-body
        />

    </div>

</template>



<script>

    import BusinessApprenticeContract from '@core/models/businessApprenticeContract';
    import BusinessApprenticeMentorship from '@core/models/businessApprenticeMentorship';
    import Collection from '@core/models/collection';
    import Pagination from '@core/mixins/pagination';

    export default {
        mixins: [Pagination],
        props: {
            businessApprenticeContract: {
                type: BusinessApprenticeContract,
                required: true,
            },
            isPending: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            paginationCollection: new Collection(new BusinessApprenticeMentorship(), { id: 'asc' }, 1000),
        }),
        computed: {
            businessPeople: (vue) => vue.paginationCollection.models.map(
                (businessApprenticeMentorship) => businessApprenticeMentorship.$.businessPerson,
            ),
            paginationPrefill: () => 3,
            paginationRequestData: (vue) => ({ business_apprentice_contract_id: vue.businessApprenticeContract.$.id }),
        },
    };

</script>
