<template>

    <div>

        <slot
            v-if="
                educationPeopleEducationMentorships.every((educationMentorships) => educationMentorships.isEmpty)
                    && businessPeopleBusinessApprenticeMentorships
                        .every((businessApprenticeMentorships) => businessApprenticeMentorships.isEmpty)
                    && businessPeopleBusinessDirectMentorships
                        .every((businessDirectMentorships) => businessDirectMentorships.isEmpty)
            "
            name="empty"
        />

        <div
            v-for="businessCompany in businessCompanies"
            :key="businessCompany.$.id"
        >
            <slot
                :is-pending="
                    businessPeopleBusinessApprenticeMentorships
                        .some((businessApprenticeMentorships) => businessApprenticeMentorships.isPending)
                        || businessPeopleBusinessDirectMentorships
                            .some((businessDirectMentorships) => businessDirectMentorships.isPending)
                "
                :learner-people="learnerPeopleByBusinessCompanyId[businessCompany.$.id]"
                :title="businessCompany.$.name"
                :upload="businessCompany.$.logo"
                icon="industry-alt"
            />
        </div>

        <div
            v-for="school in schools"
            :key="school.$.id"
        >
            <slot
                :is-pending="
                    educationPeopleEducationMentorships.some((educationMentorships) => educationMentorships.isPending)
                "
                :learner-people="learnerPeopleBySchoolId[school.$.id]"
                :title="school.$.name"
                :upload="school.$.logoSm"
                icon="school"
            />
        </div>

    </div>

</template>



<script>

    import BusinessApprenticeMentorship from '@core/models/businessApprenticeMentorship';
    import BusinessDirectMentorship from '@core/models/businessDirectMentorship';
    import Collection from '@core/models/collection';
    import EducationMentorship from '@core/models/educationMentorship';

    export default {
        props: {
            isCurrent: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            businessPeopleBusinessApprenticeMentorships: [],
            businessPeopleBusinessDirectMentorships: [],
            educationPeopleEducationMentorships: [],
        }),
        computed: {
            businessApprenticeMentorships: (vue) => vue.businessPeopleBusinessApprenticeMentorships
                .map((businessApprenticeMentorships) => businessApprenticeMentorships.models)
                .flat(),
            businessCompanies: (vue) => [
                ...vue.businessApprenticeMentorships.map(
                    (businessApprenticeMentorship) => businessApprenticeMentorship
                        .$.businessApprenticeContract
                        .$.executingBusinessBranch
                        .$.businessCompany,
                ),
                ...vue.businessDirectMentorships.map(
                    (businessDirectMentorship) => businessDirectMentorship.$.businessBranch.$.businessCompany,
                ),
            ].filter((element, index, array) => array.findIndex((e) => element.is(e)) === index),
            businessDirectMentorships: (vue) => vue.businessPeopleBusinessDirectMentorships
                .map((businessDirectMentorships) => businessDirectMentorships.models)
                .flat(),
            businessPeople: (vue) => vue.$store.getters['auth/businessPeople'].filter(
                (businessPerson) => businessPerson.$.hasBusinessDirectMentorships
                    || businessPerson.$.hasOfficialBusinessApprenticeMentorships
                    || businessPerson.$.hasUnofficialBusinessApprenticeMentorships,
            ),
            educationMentorships: (vue) => vue.educationPeopleEducationMentorships
                .map((educationMentorships) => educationMentorships.models)
                .flat(),
            educationPeople: (vue) => vue.$store.getters['auth/educationPeople'].filter(
                (educationPerson) => educationPerson.$.hasEducationMentorships,
            ),
            learnerPeopleByBusinessCompanyId: (vue) => vue.businessCompanies.reduce(
                (learnerPeople, businessCompany) => ({
                    [businessCompany.$.id]: [
                        ...vue.businessApprenticeMentorships
                            .filter(
                                (businessApprenticeMentorship) => businessCompany.is(
                                    businessApprenticeMentorship
                                        .$.businessApprenticeContract
                                        .$.executingBusinessBranch
                                        .$.businessCompany,
                                ),
                            )
                            .map(
                                (businessApprenticeMentorship) => businessApprenticeMentorship
                                    .$.businessApprenticeContract
                                    .$.learnerPerson,
                            ),
                        ...vue.businessDirectMentorships
                            .filter(
                                (businessDirectMentorship) => businessCompany.is(
                                    businessDirectMentorship.$.businessBranch.$.businessCompany,
                                ),
                            )
                            .map((businessMentorship) => businessMentorship.$.learnerPerson),
                    ].filter((element, index, array) => array.findIndex((e) => element.is(e)) === index),
                    ...learnerPeople,
                }),
                {},
            ),
            learnerPeopleBySchoolId: (vue) => vue.schools.reduce((learnerPeople, school) => ({
                [school.$.id]: vue.educationMentorships
                    .filter((educationMentorship) => school.is(educationMentorship.$.educationPerson.$.school))
                    .map((educationMentorship) => educationMentorship.$.learnerPerson)
                    .filter((element, index, array) => array.findIndex((e) => element.is(e)) === index),
                ...learnerPeople,
            }), {}),
            schools: (vue) => vue.educationMentorships
                .map((educationMentorship) => educationMentorship.$.educationPerson.$.school)
                .filter((element, index, array) => array.findIndex((e) => element.is(e)) === index),
        },
        watch: {
            isCurrent: {
                handler() {
                    this.businessPeopleBusinessApprenticeMentorships = this.businessPeople.map((businessPerson) => {
                        const businessApprenticeMentorships = new Collection(
                            new BusinessApprenticeMentorship(),
                            {},
                            1000,
                        );
                        businessApprenticeMentorships.prefill(3).request().index({
                            business_person_id: businessPerson.$.id,
                            isCurrent: this.isCurrent ? true : undefined,
                        });
                        return businessApprenticeMentorships;
                    });
                    this.businessPeopleBusinessDirectMentorships = this.businessPeople.map((businessPerson) => {
                        const businessDirectMentorships = new Collection(new BusinessDirectMentorship(), {}, 1000);
                        businessDirectMentorships.prefill(3).request().index({
                            business_person_id: businessPerson.$.id,
                            isCurrent: this.isCurrent ? true : undefined,
                        });
                        return businessDirectMentorships;
                    });
                    this.educationPeopleEducationMentorships = this.educationPeople.map((educationPerson) => {
                        const educationMentorships = new Collection(new EducationMentorship(), {}, 1000);
                        educationMentorships.prefill(3).request().index({
                            education_person_id: educationPerson.$.id,
                            isCurrent: this.isCurrent ? true : undefined,
                        });
                        return educationMentorships;
                    });
                },
                immediate: true,
            },
        },
    };

</script>
