<template>

    <b-container fluid>

        <h1 class="text-center">
            {{ pageTitle }}
        </h1>

        <div class="text-center mb-4">
            <b-form-checkbox
                v-model="isCurrent"
                switch
            >
                Uniquement les suivis en cours
            </b-form-checkbox>
        </div>

        <learner-person-index :is-current="isCurrent">

            <template #empty>
                <b-alert
                    class="mb-4 text-center"
                    variant="warning"
                    show
                >

                    <font-awesome-icon
                        :icon="['fad', 'empty-set']"
                        fixed-width
                    />

                    Vous n'avez aucun suivi <b v-if="isCurrent">en cours</b>.<br>

                    <b-button
                        v-if="isCurrent"
                        :pressed.sync="isCurrent"
                        class="mt-1"
                        variant="warning"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'eye']"
                            fixed-width
                        />
                        Afficher tous les suivis
                    </b-button>

                </b-alert>
            </template>

            <template #default="{ icon, isPending, learnerPeople, title, upload }">
                <div class="mb-4">

                    <h2 class="d-flex mb-3">

                        <upload-avatar
                            :icon="icon"
                            :upload="upload"
                            width="25px"
                        />

                        <div class="flex-fill ml-2">
                            {{ title }}
                        </div>

                    </h2>

                    <b-alert
                        class="text-center"
                        variant="warning"
                        :show="learnerPeople.length === 0"
                    >
                        <b-td colspan="3">
                            <font-awesome-icon
                                :icon="['fad', 'empty-set']"
                                fixed-width
                            />
                            Aucun apprenant.
                        </b-td>
                    </b-alert>

                    <home-summary-learner-person
                        v-for="learnerPerson in learnerPeople"
                        :key="learnerPerson.$.id"
                        :is-pending="isPending"
                        :learner-person="learnerPerson"
                    />

                </div>
            </template>

        </learner-person-index>

    </b-container>

</template>



<script>
    export default {
        data: () => ({
            isCurrent: true,
        }),
        computed: {
            pageTitle: () => 'Mes apprenants',
        },
    };
</script>



<style scoped>

    td:last-child {
        min-width: 50%;
    }

</style>
