<template>

    <div>

        <b-alert
            :show="paginationCollection.isEmpty"
            :variant="
                schooling === 'apprentice_search' || schooling === 'apprentice_terminated' ? 'warning' : 'success'
            "
            class="text-center"
        >
            <font-awesome-icon
                :icon="[
                    'fad',
                    schooling === 'apprentice_search' || schooling === 'apprentice_terminated' ? 'empty-set' : 'check',
                ]"
                fixed-width
            />
            L'apprenant ne possède aucun contrat d'apprentissage.
        </b-alert>

        <b-form-row
            v-for="businessApprenticeContract in paginationCollection.models"
            :key="businessApprenticeContract.$.id"
            class="w-100"
        >

            <b-col
                class="d-flex align-items-center justify-content-end py-2 small text-break text-muted"
                cols="3"
            >

                <div
                    v-if="paginationCollection.isPending"
                    class="flex-fill"
                >
                    <b-skeleton
                        class="w-75"
                        variant="light"
                    />
                    <b-skeleton
                        class="w-100"
                        variant="light"
                    />
                </div>

                <div v-else>

                    {{ dayjsShortDate(businessApprenticeContract.$.start) }}<br>

                    <component :is="businessApprenticeContract.$.terminated_at ? 's' : 'span'">
                        {{ dayjsShortDate(businessApprenticeContract.$.end) }}
                    </component>

                    <span
                        v-if="businessApprenticeContract.$.terminated_at"
                        class="text-warning"
                    >
                        {{ dayjsShortDate(businessApprenticeContract.$.terminated_at) }}
                    </span>

                </div>

            </b-col>

            <b-col
                class="d-flex align-items-center justify-content-center py-2 text-center"
                cols="2"
            >

                <b-skeleton-img
                    v-if="paginationCollection.isPending"
                    aspect="1:1"
                />

                <upload-avatar
                    v-else
                    :upload="businessApprenticeContract.$.executingBusinessBranch.$.businessCompany.$.logo"
                    class="bg-body"
                    icon="industry-alt"
                    width="75%"
                    rounded
                />

            </b-col>

            <b-col
                class="d-flex align-items-center py-2"
                cols="7"
            >

                <b-skeleton
                    v-if="paginationCollection.isPending"
                    class="w-100"
                    height="4rem"
                    type="button"
                    variant="primary"
                />

                <b-button
                    v-else
                    :to="{
                        name: `businessApprenticeContract-show`,
                        params: { businessApprenticeContractId: businessApprenticeContract.$.id },
                    }"
                    variant="outline-primary"
                    block
                >

                    {{ businessApprenticeContract.$.job_title }}<br>

                    <b-badge
                        variant="light"
                        class="mt-2 text-wrap"
                    >
                        {{ businessApprenticeContract.$.executingBusinessBranch.$.businessCompany.$.name }}
                    </b-badge>

                </b-button>

            </b-col>

        </b-form-row>

    </div>

</template>



<script>

    import BusinessApprenticeContract from '@core/models/businessApprenticeContract';
    import Collection from '@core/models/collection';
    import Dayjs from '@core/mixins/dayjs';
    import Pagination from '@core/mixins/pagination';

    export default {
        mixins: [Dayjs, Pagination],
        props: {
            learnerPersonId: {
                type: Number,
                required: true,
            },
        },
        data: () => ({
            paginationCollection: new Collection(new BusinessApprenticeContract(), { start: 'desc' }, 1000),
        }),
        computed: {
            learnerEducationStudy: (vue) => vue.$store.getters['learnerPerson/learnerEducationStudy'],
            paginationPrefill: () => 5,
            paginationRequestData: (vue) => ({ learner_person_id: vue.learnerPersonId }),
            schooling: (vue) => (vue.learnerEducationStudy ? vue.learnerEducationStudy.$.schooling : undefined),
        },
    };

</script>



<style scoped>

    .col-2::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 1px;
        background: lightgray;
        z-index: -1;
    }

    .col-2 .b-aspect {
        width: 75%;
        max-width: 50px;
        background: white;
    }

</style>
