<template>

    <div>

        <h1 class="mb-4">

            <b-skeleton
                v-if="businessApprenticeContract.isPending"
                class="w-75 py-3"
                variant="dark"
            />

            <div v-else>
                {{ pageTitle }}
            </div>

            <b-skeleton
                v-if="businessApprenticeContract.isPending"
                class="w-50 py-2"
                variant="light"
            />

            <small
                v-else
                class="mb-4 text-muted"
            >

                Exécution du <b>{{ dayjsShortDate(businessApprenticeContract.$.start) }}</b> au

                <component
                    :is="businessApprenticeContract.$.terminated_at ? 's' : 'span'"
                    class="font-weight-bold"
                >
                    {{ dayjsShortDate(businessApprenticeContract.$.end) }}
                </component>

                <b
                    v-if="businessApprenticeContract.$.terminated_at"
                    class="text-warning"
                >
                    {{ dayjsShortDate(businessApprenticeContract.$.terminated_at) }}
                </b>

            </small>

        </h1>

        <b-row>

            <b-col md="6">

                <business-apprentice-contract-business-branch
                    :business-branch="businessApprenticeContract.$.executingBusinessBranch"
                    :is-pending="businessApprenticeContract.isPending"
                    :title="singleBusinessBranch ? 'Employeur' : 'Établissement d\'exécution'"
                    class="mb-4"
                />

                <business-apprentice-contract-business-branch
                    v-if="singleBusinessBranch !== true"
                    :business-branch="businessApprenticeContract.$.signatoryBusinessBranch"
                    :is-pending="businessApprenticeContract.isPending"
                    class="mb-4"
                    title="Employeur"
                />

                <b-card
                    bg-variant="light"
                    class="mb-4"
                    header="Lieu d'éxécution"
                    header-tag="h2"
                >

                    <b-alert
                        v-if="businessApprenticeContract.$.location.isEmpty"
                        variant="warning"
                        show
                    >
                        <font-awesome-icon
                            :icon="['fad', 'empty-set']"
                            fixed-width
                        />
                        Le lieu d'éxécution du contrat n'a pas été renseigné.
                    </b-alert>

                    <address-show
                        v-else
                        :address="businessApprenticeContract.$.location"
                        :pending="businessApprenticeContract.isPending"
                    />

                </b-card>

            </b-col>

            <b-col md="6">
                <business-apprentice-contract-business-apprentice-mentorship
                    :business-apprentice-contract="businessApprenticeContract"
                    :is-pending="businessApprenticeContract.isPending"
                    class="mb-4"
                />
            </b-col>

            <b-col cols="12">
                <b-card
                    v-for="questionnaireEntry in paginationCollection.models"
                    :key="questionnaireEntry.$.id"
                    class="mb-4"
                    no-body
                >

                    <b-card-header
                        class="p-0"
                        header-tag="header"
                        role="tab"
                    >
                        <b-button
                            v-b-toggle="`accordion-questionnaire-entry-${questionnaireEntry.$.id}`"
                            :variant="questionnaireEntry.variant()"
                            class="text-left pb-2"
                            block
                        >
                            <h2 class="mb-0">

                                <b-skeleton
                                    v-if="questionnaireEntry.isPending"
                                    class="w-75 py-3"
                                    variant="dark"
                                />

                                <template
                                    v-else
                                >
                                    <font-awesome-icon
                                        :icon="['fad', questionnaireEntry.icon()]"
                                        fixed-width
                                    />
                                    {{ questionnaireEntry.$.questionnaireSurvey.$.name }}
                                </template>

                            </h2>
                        </b-button>
                    </b-card-header>

                    <b-collapse
                        :id="`accordion-questionnaire-entry-${questionnaireEntry.$.id}`"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <questionnaire-entry-show
                                :questionnaire-entry-id="questionnaireEntry.$.id"
                                no-header
                                @input:questionnaireEntry="updateQuestionnaireEntry($event)"
                            />
                        </b-card-body>
                    </b-collapse>

                </b-card>
            </b-col>

        </b-row>

    </div>

</template>



<script>

    import BusinessApprenticeContract from '@core/models/businessApprenticeContract';
    import Collection from '@core/models/collection';
    import Dayjs from '@core/mixins/dayjs';
    import Pagination from '@core/mixins/pagination';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';

    export default {
        mixins: [Dayjs, Pagination],
        props: {
            businessApprenticeContractId: {
                type: [Number, String],
                required: true,
            },
        },
        data: () => ({
            businessApprenticeContract: new BusinessApprenticeContract(),
            paginationCollection: new Collection(new QuestionnaireEntry(), { end: 'asc' }, 1000),
        }),
        computed: {
            pageTitle: (vue) => vue.businessApprenticeContract.$.job_title,
            paginationPrefill: () => 0,
            paginationRequestData: (vue) => ({
                business_apprentice_contract_id: vue.businessApprenticeContractId,
            }),
            singleBusinessBranch: (vue) => vue.businessApprenticeContract.$.executingBusinessBranch.is(
                vue.businessApprenticeContract.$.signatoryBusinessBranch,
            ),
        },
        watch: {
            businessApprenticeContractId: {
                handler() {
                    this.businessApprenticeContract.fill({ id: this.businessApprenticeContractId })
                        .request()
                        .show()
                        .catch(() => this.$router.push({ name: 'businessApprenticeContract-index' }));
                },
                immediate: true,
            },
        },
        methods: {
            updateQuestionnaireEntry(updatedQuestionnaireEntry) {
                this.paginationCollection.models = this.paginationCollection.models.map((questionnaireEntry) => (
                    questionnaireEntry.is(updatedQuestionnaireEntry) ? updatedQuestionnaireEntry : questionnaireEntry
                ));
            },
        },
    };

</script>



<style scoped>

    :deep(.bg-body) {
        background: white;
    }

</style>
