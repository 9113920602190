<template>

    <b-card
        class="mb-3 flex-sm-row"
        no-body
    >

        <b-card-header class="d-flex align-items-center col-sm-4 col-lg-3 pb-2">

            <b-skeleton-img
                v-if="isPending"
                class="mr-2"
                height="50px"
                width="50px"
                no-aspect
            />

            <upload-avatar
                v-else
                :upload="learnerPerson.$.avatar"
                icon="user-graduate"
                width="50px"
                rounded
            />

            <div
                v-if="isPending"
                class="flex-fill ml-2"
            >
                <b-skeleton
                    class="w-50"
                    variant="dark"
                />
                <b-skeleton class="w-100" />
                <b-skeleton class="w-100" />
                <b-skeleton class="w-25" />
                <b-skeleton class="w-75" />
                <b-skeleton class="w-50" />
            </div>

            <h3
                v-else
                class="flex-fill ml-2 mb-0"
            >

                <div class="mb-1">
                    {{ learnerPerson.$.lastname }} {{ learnerPerson.$.firstname }}
                </div>

                <div
                    v-if="learnerPerson.learnerEducationStudy"
                    class="mb-1 small text-muted"
                >
                    {{ learnerPerson.learnerEducationStudy.$.educationStudy.$.name }}
                    ({{ learnerPerson.learnerEducationStudy.$.educationStudy.yearAsText }})
                </div>

                <div
                    v-if="businessApprenticeContracts.isPending"
                    class="mb-1"
                >
                    <b-skeleton class="w-75" />
                </div>

                <div
                    v-else-if="businessBranch"
                    class="mb-1 small text-muted"
                >
                    {{ businessBranch.$.businessCompany.$.name }}
                </div>

                <div
                    v-else
                    class="mb-1 small text-muted"
                >
                    <i>
                        Aucun contrat en cours.
                    </i>
                </div>

                <div
                    v-if="learnerPerson.$.primaryAddress.$.mobile_phone"
                    class="mb-1 small text-muted"
                >

                    <font-awesome-icon
                        :icon="['fad', 'mobile']"
                        fixed-width
                    />

                    <a :href="`tel:${learnerPerson.$.primaryAddress.$.mobile_phone}`">
                        {{ learnerPerson.$.primaryAddress.$.mobile_phone }}
                    </a>
                </div>

                <div
                    v-if="learnerPerson.$.primaryAddress.$.landline_phone"
                    class="mb-1 small text-muted"
                >

                    <font-awesome-icon
                        :icon="['fad', 'phone']"
                        fixed-width
                    />

                    <a :href="`tel:${learnerPerson.$.primaryAddress.$.landline_phone}`">
                        {{ learnerPerson.$.primaryAddress.$.landline_phone }}
                    </a>
                </div>

                <div
                    v-if="learnerPerson.$.secondaryAddress.$.mobile_phone"
                    class="mb-1 small text-muted"
                >

                    <font-awesome-icon
                        :icon="['fad', 'mobile']"
                        fixed-width
                    />

                    <a :href="`tel:${learnerPerson.$.secondaryAddress.$.mobile_phone}`">
                        {{ learnerPerson.$.secondaryAddress.$.mobile_phone }}
                    </a>
                </div>

                <div
                    v-if="learnerPerson.$.secondaryAddress.$.landline_phone"
                    class="mb-1 small text-muted"
                >

                    <font-awesome-icon
                        :icon="['fad', 'phone']"
                        fixed-width
                    />

                    <a :href="`tel:${learnerPerson.$.secondaryAddress.$.landline_phone}`">
                        {{ learnerPerson.$.secondaryAddress.$.landline_phone }}
                    </a>
                </div>

                <div
                    v-if="learnerPerson.learnerEducationStudy && learnerPerson.learnerEducationStudy.$.user.$.email"
                    class="mb-1 small text-muted"
                >

                    <font-awesome-icon
                        :icon="['fad', 'envelope']"
                        fixed-width
                    />

                    <a :href="`mailto:${learnerPerson.learnerEducationStudy.$.user.$.email}`">
                        {{ learnerPerson.learnerEducationStudy.$.user.$.email }}
                    </a>
                </div>

            </h3>

        </b-card-header>

        <b-alert
            class="d-flex justify-content-between align-items-center mb-0 col-sm-8 col-lg-9 rounded-0 rounded-right"
            variant="info"
            :show="paginationCollection.isEmpty"
        >

            <div>
                <font-awesome-icon
                    :icon="['fad', 'info']"
                    fixed-width
                />
                Le livret de suivi de cet apprenant est vide ou entièrement validé.
            </div>

            <b-button
                :to="{
                    name: 'questionnaireEntry-index',
                    params: { mandatory: 1, questionnaireCategoryId: 0 },
                    query: { learner_person_id: learnerPerson.$.id },
                }"
                class="ml-2 text-decoration-none"
                variant="outline-light"
            >
                <font-awesome-icon
                    :icon="['fad', 'folder-open']"
                    fixed-width
                />
                Livret de suivi
            </b-button>

        </b-alert>

        <div
            v-if="paginationCollection.isEmpty !== true"
            class="col-sm-8 col-lg-9 d-flex flex-wrap align-items-center"
        >

            <b-skeleton
                v-if="isPending"
                class="h-75 mx-2 align-self-center"
                variant="primary"
                type="button"
            />

            <b-button
                v-else
                :to="{
                    name: 'questionnaireEntry-index',
                    params: { mandatory: 1, questionnaireCategoryId: 0 },
                    query: { learner_person_id: learnerPerson.$.id },
                }"
                class="mx-2 align-self-center"
                variant="outline-primary"
            >
                <font-awesome-icon
                    :icon="['fad', 'folder-open']"
                    fixed-width
                />
                <br>
                Livret de suivi
            </b-button>

            <questionnaire-entry-square
                v-for="questionnaireEntry in paginationCollection.models"
                :key="questionnaireEntry.$.id"
                :is-pending="paginationCollection.isPending"
                :questionnaire-entry="questionnaireEntry"
                :to="questionnaireEntry.$.id ? {
                    name: 'questionnaireEntry-show',
                    params: {
                        mandatory: questionnaireEntry.$.questionnaireSurvey.$.mandatory ? 1 : 0,
                        questionnaireCategoryId: 0,
                        questionnaireEntryId: questionnaireEntry.$.id,
                    },
                    query: { learner_person_id: learnerPerson.$.id },
                } : {}"
            />

        </div>

    </b-card>


</template>



<script>

    import BusinessApprenticeContract from '@core/models/businessApprenticeContract';
    import Collection from '@core/models/collection';
    import Model from '@core/models/_rest';
    import LearnerPerson from '@core/models/learnerPerson';
    import QuestionnaireEntry from '@core/models/questionnaireEntry';
    import Pagination from '@core/mixins/pagination';

    export default {
        mixins: [Pagination],
        props: {
            isPending: {
                type: Boolean,
                default: false,
            },
            learnerPerson: {
                type: LearnerPerson,
                required: true,
            },
        },
        data: () => ({
            businessApprenticeContracts: new Collection(new BusinessApprenticeContract(), {}, 1),
            paginationCollection: new Collection(new Model(), {}),
        }),
        computed: {
            businessBranch: (vue) => (
                vue.businessApprenticeContracts.isEmpty
                    ? undefined
                    : vue.businessApprenticeContracts.models[0].$.signatoryBusinessBranch
            ),
            paginationRequestData: (vue) => ({
                isNotValidated: 1,
                learner_person_id: vue.learnerPerson.$.id,
                mandatory: 1,
            }),
        },
        watch: {
            learnerPerson: {
                handler() {
                    if (this.learnerPerson.$.id && this.paginationCollection.model.url === undefined) {
                        this.paginationCollection = new Collection(new QuestionnaireEntry(), { end: 'asc' }, 1000);
                        this.paginationRequest();
                        this.businessApprenticeContracts.prefill(1).request().index({
                            isCurrent: 1,
                            learner_person_id: this.learnerPerson.$.id,
                        });
                    }
                },
                immediate: true,
            },
        },
    };

</script>
