<template>

    <b-card
        :header="`Profil ${formInitialModel.$.school.$.name}`"
        header-class="text-center"
        header-tag="h2"
        tag="form"
        @submit.prevent="formSubmit"
    >

        <form-group
            id="view-educationPerson-edit-title"
            v-slot="inputProps"
            :error="formModifiedModel.errors.title"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.title"
            label="Titre"
            required
        >
            <b-form-radio-group
                v-model="formModifiedModel.$.title"
                v-bind="inputProps"
                :disabled="formInitialModel.$.updatable !== true"
                :options="titles"
            />
        </form-group>

        <form-group
            id="view-educationPerson-edit-employer"
            v-slot="inputProps"
            :error="formModifiedModel.errors.employer"
            :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.employer"
            label="Employeur"
            required
        >
            <b-form-input
                v-model="formModifiedModel.$.employer"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
            />
        </form-group>

        <form-group
            id="view-educationPerson-edit-job-title"
            v-slot="inputProps"
            :error="formModifiedModel.errors.job_title"
            :left-icon="formInitialModel.$.updatable ? 'text' : undefined"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.job_title"
            label="Fonction"
            business-apprentice-agreement
            required
        >
            <b-form-input
                v-model="formModifiedModel.$.job_title"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
            />
        </form-group>

        <form-group
            id="view-educationPerson-edit-direct_phone"
            v-slot="inputProps"
            :error="formModifiedModel.errors.direct_phone"
            :left-icon="formInitialModel.$.updatable ? 'mobile' : undefined"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.direct_phone"
            label="Téléphone : direct"
        >
            <b-form-input
                v-model="formModifiedModel.$.direct_phone"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                pattern="^\+?\d{7,14}$"
                title="7 à 14 chiffres"
            />
        </form-group>

        <form-group
            id="view-educationPerson-edit-mobile_phone"
            v-slot="inputProps"
            :error="formModifiedModel.errors.mobile_phone"
            :left-icon="formInitialModel.$.updatable ? 'mobile' : undefined"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.mobile_phone"
            label="Téléphone : portable"
        >
            <b-form-input
                v-model="formModifiedModel.$.mobile_phone"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                pattern="^\+?\d{7,14}$"
                title="7 à 14 chiffres"
            />
        </form-group>

        <form-group
            id="view-educationPerson-edit-reception_phone"
            v-slot="inputProps"
            :error="formModifiedModel.errors.reception_phone"
            :left-icon="formInitialModel.$.updatable ? 'mobile' : undefined"
            :pending="formInitialModel.isPending"
            :state="formModifiedModel.states.reception_phone"
            label="Téléphone : réception"
        >
            <b-form-input
                v-model="formModifiedModel.$.reception_phone"
                v-bind="inputProps"
                :plaintext="formInitialModel.$.updatable !== true"
                pattern="^\+?\d{7,14}$"
                title="7 à 14 chiffres"
            />
        </form-group>

        <div
            v-if="formInitialModel.$.updatable"
            class="text-right"
        >
            <button-submit
                :is-pending="formIsPending"
                icon="edit"
                variant="success"
            >
                Enregistrer le profil
            </button-submit>
        </div>

    </b-card>

</template>



<script>

    import Form from '@core/mixins/form';
    import EducationPerson from '@core/models/educationPerson';

    export default {
        mixins: [Form],
        props: {
            value: {
                type: EducationPerson,
                required: true,
            },
        },
        computed: {
            formInitialModel: {
                get: (vue) => vue.value,
                set(value) {
                    this.$emit('input', value);
                },
            },
            titles: () => Object.entries(EducationPerson.titles).map(
                ([value, text]) => ({ text, value }),
            ),
        },
        methods: {
            formSubmitSuccessful(updatedEducationPerson) {
                this.$root.toastSuccess(
                    `Les informations du profil ${updatedEducationPerson.$.school.$.name} ont été modifiées`,
                    "L'enregistrement des informations a réussi.",
                );
                this.formInitialModel = new EducationPerson(updatedEducationPerson.$);
            },
        },
    };

</script>
