<template>

    <b-container fluid>
        <b-row>

            <b-col
                class="mb-4"
                cols="12"
                md="4"
            >

                <div class="d-md-none mb-4 mb-md-0 text-center">
                    <b-button
                        :pressed.sync="showBusinessApprenticeContractIndex"
                        variant="outline-primary"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'file-contract']"
                            fixed-width
                        />
                        Parcourir les contrats
                    </b-button>
                </div>

                <b-collapse
                    v-model="showBusinessApprenticeContractIndex"
                    class="position-sticky d-md-block"
                >
                    <business-apprentice-contract-timeline :learner-person-id="learnerPersonId" />
                </b-collapse>

            </b-col>

            <b-col
                cols="12"
                md="8"
            >

                <b-alert
                    v-if="
                        schoolingKey && schoolingKey !== 'apprentice_search' && schoolingKey !== 'apprentice_terminated'
                    "
                    class="d-none d-md-block"
                    variant="info"
                    show
                >
                    <font-awesome-icon
                        :icon="['fad', 'info']"
                        fixed-width
                    />
                    L'apprenant est inscrit en tant que {{ schoolingName }}.
                </b-alert>

                <b-alert
                    v-else
                    :show="!$route.params.businessApprenticeContractId"
                    class="d-none d-md-block"
                    variant="info"
                >
                    <font-awesome-icon
                        :icon="['fad', 'info']"
                        fixed-width
                    />
                    Sélectionnez un contrat dans la liste ci-contre pour le consulter.
                </b-alert>

                <router-view />

            </b-col>

        </b-row>
    </b-container>

</template>



<script>

    import LearnerEducationStudy from '@core/models/learnerEducationStudy';

    export default {
        beforeRouteEnter: (to, from, next) => {
            if (to.query.learner_person_id === undefined) {
                next({ name: 'home-summary' });
            } else {
                next();
            }
        },
        data: () => ({
            showBusinessApprenticeContractIndex: false,
        }),
        computed: {
            learnerEducationStudy: (vue) => vue.$store.getters['learnerPerson/learnerEducationStudy'],
            learnerPersonId: (vue) => vue.$store.getters['learnerPerson/id'],
            pageTitle: () => 'Missions & contrats',
            schoolingKey: (vue) => (vue.learnerEducationStudy ? vue.learnerEducationStudy.$.schooling : undefined),
            schoolingName: (vue) => LearnerEducationStudy.schoolings[vue.schoolingKey],
        },
    };

</script>
